import React, { Component } from "react"
import { Link, graphql } from "gatsby"
import ImageMeta from "../components/ImageMeta"
import { getMonthYearFromStrings } from "../utils/utils"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import ReviewQueryCarousel from "../components/carousel/ReviewQueryCarousel"

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
const converter = new showdown.Converter()

function SpanishReviewsSection(props) {
  const language = props.language
  const spanishReviews = props.spanishReviews
  const spanishReferringProviders = props.spanishReferringProviders

  function SingleStaticReview(props) {
    return (
      <div className="column is-9">
        <Link to={props.title}>
          <ImageMeta
            cloudName="nuvolum"
            className="hoverable"
            publicId={props.mainPhotoPublicId}
            responsive
            width="auto"
          ></ImageMeta>
        </Link>
        <Link className="image-caption-link" to={props.title}>
          <div className="image-caption-wrapper">
            <h6 className="image-caption staff">
              Conoce a {props.reviewerName}
            </h6>
            <h6 className="image-caption staff staff-title light">
              {getMonthYearFromStrings(
                props.monthYear.year,
                props.monthYear.month,
                language
              )}
            </h6>
          </div>
        </Link>
      </div>
    )
  }

  return (
    <section
      className="body-sections section"
      style={{ paddingBottom: "80px", paddingTop: "0" }}
    >
      <div className="column is-24">
        <div className="columns">
          <div className="column is-2"></div>
          <SingleStaticReview {...spanishReviews[0]} />
          <div className="column is-2"></div>
          <SingleStaticReview {...spanishReviews[1]} />
          <div className="column is-2"></div>
        </div>
        <div className="columns">
          <div className="column is-2"></div>
          <SingleStaticReview {...spanishReviews[2]} />
          <div className="column is-2"></div>
          <SingleStaticReview {...spanishReferringProviders[0]} />
          <div className="column is-2"></div>
        </div>
      </div>
    </section>
  )
}

function DefaultReviewsSection(props) {
  return (
    <>
      {/* {props.post.hasDentalImplants && (
        <section className="patient-reviews-section">
          <div
            className="container"
            data-aos="fade-up"
            data-aos-duration="1200"
          >
            <div className="columns">
              <div className="column is-4"></div>
              <div className="column"></div>
            </div>
          </div>
          <ReviewQueryCarousel
            carouselTypeTextTitle="Patients"
            className="dental-implants"
            id="slide-dental-implants"
            relatedReviews={props.data.relatedDentalImplants.nodes}
            carouselName="Dental Implant"
          />
        </section>
      )}
      {props.post.hasWisdomTeeth && (
        <section className="patient-reviews-section">
          <div
            className="container"
            data-aos="fade-up"
            data-aos-duration="1200"
          >
            <div className="columns">
              <div className="column is-4"></div>
              <div className="column"></div>
            </div>
          </div>
  
          <ReviewQueryCarousel
            carouselTypeTextTitle="Patients"
            className="wisdom-teeth"
            id="slide-wisdom-teeth"
            relatedReviews={props.data.relatedWisdomTeeth.nodes}
            carouselName="Wisdom Teeth"
          />
        </section>
      )}
      {props.post.hasToothExtraction && (
        <section className="patient-reviews-section">
          <div
            className="container"
            data-aos="fade-up"
            data-aos-duration="1200"
          >
            <div className="columns">
              <div className="column is-4"></div>
              <div className="column"></div>
            </div>
          </div>
  
          <ReviewQueryCarousel
            carouselTypeTextTitle="Patients"
            className="tooth-extraction"
            id="slide-tooth-extraction"
            relatedReviews={props.data.relatedExtractions.nodes}
            carouselName="Tooth Extraction"
          />
        </section>
      )}
      {props.post.hasOther && props.otherReviews.length > 0 && (
        <section className="patient-reviews-section">
          <div
            className="container"
            data-aos="fade-up"
            data-aos-duration="1200"
          >
            <div className="columns">
              <div className="column is-4"></div>
              <div className="column"></div>
            </div>
          </div>
  
          <ReviewQueryCarousel
            carouselTypeTextTitle="Patients"
            className="other"
            id="slide-other"
            relatedReviews={props.otherReviews}
            carouselName="Other"
          />
        </section>
      )} */}

      <section className="patient-reviews-section">
        <div className="container" data-aos="fade-up" data-aos-duration="1200">
          <div className="columns">
            <div className="column is-4"></div>
            <div className="column"></div>
          </div>
        </div>

        <ReviewQueryCarousel
          carouselTypeTextTitle="Patients"
          className="other"
          id="slide-other"
          relatedReviews={props.allReviews}
          carouselName="Our"
        />
      </section>

      {props.post.hasReferringProvider && (
        <section
          className="patient-reviews-section"
          style={{ paddingBottom: "72px" }}
        >
          <div
            className="container"
            data-aos="fade-up"
            data-aos-duration="1200"
          >
            <div className="columns">
              <div className="column is-4"></div>
              <div className="column"></div>
            </div>
          </div>

          <ReviewQueryCarousel
            carouselTypeTextTitle="Providers"
            className="referring-providers"
            id="slide-referring-providers"
            relatedReviews={props.data.referringProviders.nodes}
            carouselName="Our Referring"
          />
        </section>
      )}
    </>
  )
}

class PatientStories extends Component {
  constructor(props) {
    super(props)
    this.state = {
      didScroll: false,
      scrollPosition: "fixed",
      scrollOverflow: "hidden",
      playing: false
    }
    this.handleFixed = this.handleFixed.bind(this)
  }

  handleFixed() {
    var that = this
    that.setState({ playing: true })
    setTimeout(() => {
      // console.log("!!!!!!!!!!!!!!!")
      that.setState({ scrollPosition: "relative" })
      that.setState({ scrollOverflow: "unset" })
    }, 200)

    window.removeEventListener("scroll", this.handleFixed, false)
  }
  // componentWillMount(){
  //   window.scrollTo(0, 0);
  // }
  componentDidMount() {
    document.body.style.height = "103vh"
    document.body.style.overflow = "scroll !important"
    window.addEventListener("scroll", this.handleFixed)
  }

  render() {
    var pageContext = this.props.pageContext
    var post
    var language

    if (pageContext && pageContext.language) {
      language = pageContext.language
      switch (language) {
        case "en":
          post = this.props.data.allUniquePagesJson.nodes[0]
          break
        case "es":
          post = this.props.data.allSpanishUniquePagesJson.nodes[0]

          break
        default:
          post = this.props.data.allUniquePagesJson.nodes[0]
      }
    } else {
      post = this.props.data.allUniquePagesJson.nodes[0]
    }

    var otherReviews = []
    var allReviews = this.props.data.relatedOther.nodes
    for (let i = 0; i < allReviews.length; i++) {
      if (
        allReviews[i].mainProcedureDone !== "Dental Implants" &&
        allReviews[i].mainProcedureDone !== "Wisdom Teeth Removal" &&
        allReviews[i].mainProcedureDone !== "Tooth Extractions"
      ) {
        for (let j = 0; j < allReviews[i].proceduresDone.length; j++) {
          if (
            allReviews[i].proceduresDone[j].procedureDone.procedureName &&
            "Dental Implants" &&
            allReviews[i].proceduresDone[j].procedureDone.procedureName &&
            "Wisdom Teeth Removal" &&
            allReviews[i].proceduresDone[j].procedureDone.procedureName &&
            "Tooth Extractions"
          ) {
            otherReviews.push(allReviews[i])
            break
          }
        }
      }
    }

    var spanishReviews = this.props.data.allSpanishReviews.nodes
    var spanishReferringProviders = this.props.data.allSpanishReferringProviders
      .nodes

    var staticStyle = {
      position: this.state.scrollPosition,
      overflow: this.state.scrollOverflow,
      marginTop: "88px",
      zIndex: "1"
    }

    return (
      <SharedStateProvider>
        <Layout language={language}>
          <SEO
            title={post.metaTitle}
            description={post.metaDescription}
            pathname={this.props.location.pathname}
            schemaPage={post.schemaPage ? post.schemaPage : null}
          />
          <div className="joshua-tree-content">
            <section className="patient-reviews-top-section">
              <div className="container">
                <div className="columns">
                  <div className="column">
                    <div
                      className="columns top-section text-section is-mobile"
                      style={{
                        backgroundColor: "#fff",
                        paddingBottom: language !== "es" ? "30px" : "80px"
                      }}
                    >
                      <div className="column is-2"></div>
                      <div className="column is-20">
                        <div>
                          <h1>{post.heading}</h1>
                        </div>
                        <div
                          dangerouslySetInnerHTML={createHtml(
                            converter.makeHtml(post.topBlurb)
                          )}
                        ></div>
                      </div>
                      <div className="column is-2"></div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          {language === "es" ? (
            <SpanishReviewsSection
              language={language}
              spanishReviews={spanishReviews}
              spanishReferringProviders={spanishReferringProviders}
            />
          ) : (
            <DefaultReviewsSection
              {...this.props}
              post={post}
              otherReviews={otherReviews}
              allReviews={allReviews}
            />
          )}
        </Layout>
      </SharedStateProvider>
    )
  }
}

export const query = graphql`
  query reviewsQueryEs($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        metaTitle
        metaDescription
        schemaPage
        heading
        topBlurb
        hasDentalImplants
        hasOther
        hasReferringProvider
        hasToothExtraction
        hasWisdomTeeth
      }
    }
    allSpanishUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        metaTitle
        metaDescription
        schemaPage
        heading
        topBlurb
        hasDentalImplants
        hasOther
        hasReferringProvider
        hasToothExtraction
        hasWisdomTeeth
      }
    }
    relatedDentalImplants: allReviewsJson(
      filter: {
        proceduresDone: {
          elemMatch: {
            procedureDone: { procedureName: { in: "Dental Implants" } }
          }
        }
        reviewType: { eq: "Patient" }
        reviewLanguage: { eq: "ENG" }
      }
    ) {
      nodes {
        id
        title
        metaTitle
        metaDescription
        mainProcedureDone
        proceduresDone {
          procedureDone {
            procedureName
            procedureUrl
          }
        }
        heading
        quote
        reviewLanguage
        reviewType
        reviewerName
        subheading
        thumbnailPublicId
        mainPhotoPublicId
        youtube
        monthYear {
          month
          year
        }
      }
    }
    relatedWisdomTeeth: allReviewsJson(
      filter: {
        proceduresDone: {
          elemMatch: {
            procedureDone: { procedureName: { in: "Wisdom Teeth Removal" } }
          }
        }
        reviewType: { eq: "Patient" }
        reviewLanguage: { eq: "ENG" }
      }
    ) {
      nodes {
        id
        title
        metaTitle
        metaDescription
        mainProcedureDone
        proceduresDone {
          procedureDone {
            procedureName
            procedureUrl
          }
        }
        heading
        quote
        reviewLanguage
        reviewType
        reviewerName
        subheading
        thumbnailPublicId
        mainPhotoPublicId
        youtube
        monthYear {
          month
          year
        }
      }
    }
    relatedExtractions: allReviewsJson(
      filter: {
        proceduresDone: {
          elemMatch: {
            procedureDone: { procedureName: { in: "Tooth Extractions" } }
          }
        }
        reviewType: { eq: "Patient" }
        reviewLanguage: { eq: "ENG" }
      }
    ) {
      nodes {
        id
        title
        metaTitle
        metaDescription
        mainProcedureDone
        proceduresDone {
          procedureDone {
            procedureName
            procedureUrl
          }
        }
        heading
        quote
        reviewLanguage
        reviewType
        reviewerName
        subheading
        thumbnailPublicId
        mainPhotoPublicId
        youtube
        monthYear {
          month
          year
        }
      }
    }
    relatedOther: allReviewsJson(
      filter: { reviewType: { eq: "Patient" }, reviewLanguage: { eq: "ENG" } }
    ) {
      nodes {
        id
        title
        metaTitle
        metaDescription
        mainProcedureDone
        proceduresDone {
          procedureDone {
            procedureName
            procedureUrl
          }
        }
        heading
        quote
        reviewLanguage
        reviewType
        reviewerName
        subheading
        thumbnailPublicId
        mainPhotoPublicId
        youtube
        monthYear {
          month
          year
        }
      }
    }
    allSpanishReviews: allReviewsJson(
      filter: { reviewType: { eq: "Patient" }, reviewLanguage: { eq: "ESP" } }
    ) {
      nodes {
        id
        title
        metaTitle
        metaDescription
        mainProcedureDone
        proceduresDone {
          procedureDone {
            procedureName
            procedureUrl
          }
        }
        heading
        quote
        reviewLanguage
        reviewType
        reviewerName
        subheading
        thumbnailPublicId
        mainPhotoPublicId
        youtube
        monthYear {
          month
          year
        }
      }
    }
    allSpanishReferringProviders: allReviewsJson(
      filter: { reviewType: { eq: "Provider" }, reviewLanguage: { eq: "ESP" } }
    ) {
      nodes {
        id
        title
        metaTitle
        metaDescription
        mainProcedureDone
        proceduresDone {
          procedureDone {
            procedureName
            procedureUrl
          }
        }
        heading
        quote
        reviewLanguage
        reviewType
        reviewerName
        subheading
        thumbnailPublicId
        mainPhotoPublicId
        youtube
        monthYear {
          month
          year
        }
      }
    }
    referringProviders: allReviewsJson(
      filter: { reviewType: { eq: "Provider" }, reviewLanguage: { eq: "ENG" } }
    ) {
      nodes {
        id
        title
        metaTitle
        metaDescription
        mainProcedureDone
        proceduresDone {
          procedureDone {
            procedureName
            procedureUrl
          }
        }
        heading
        quote
        reviewLanguage
        reviewType
        reviewerName
        subheading
        thumbnailPublicId
        mainPhotoPublicId
        youtube
        monthYear {
          month
          year
        }
      }
    }
  }
`

export default PatientStories
